import minLengthValidator from './min-length.validator'
import requiredValidator from './required.validator'
import sameAsPasswordValidator from './same-as-password.validator'
import uniqueValidator from './unique.validator'
import usernameCharactersValidator from './username-characters.validator'

export const minLength = minLengthValidator
export const required = requiredValidator
export const sameAsPassword = sameAsPasswordValidator
export const unique = uniqueValidator
export const usernameCharacters = usernameCharactersValidator
