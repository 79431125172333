import pDebounce from 'p-debounce'
import { helpers } from '@vuelidate/validators'

export default (callback) => {
    const validator = async (value) => {
        if (!value) {
            return true
        }

        try {
            await callback(value)
        }
        catch (error) {
            if (error.response?.status == 403) {
                return false
            }
            throw error
        }

        return true
    }

    return helpers.withMessage(
        () => `Tāds jau eksistē`,
        helpers.withAsync(pDebounce(validator, 250))
    )
}
