export default {
    namespaced: true,

    state: {
        accessToken: null,
        refreshToken: null,
        currentUser: null,
    },

    mutations: {
        login(state, {accessToken, refreshToken}) {
            state.accessToken = accessToken
            state.refreshToken = refreshToken
        },
        setCurrentUser(state, user) {
            state.currentUser = user
        },
        logout(state) {
            state.accessToken = null
            state.refreshToken = null
            state.currentUser = null
        },
    },

    actions: {
        login(context, payload) {
            context.commit('login', payload)
        },
        setCurrentUser(context, user) {
            context.commit('setCurrentUser', user)
        },
        logout(context) {
            context.commit('logout')
        },
    },

    getters: {
        accessToken(state) {
            return state.accessToken
        },
        refreshToken(state) {
            return state.refreshToken
        },
        currentUser(state) {
            return state.currentUser
        },
    },
}
