import swal from 'sweetalert'

export default {
    methods: {
        confirmAlert(message) {
            return swal({
                text: message,
                icon: 'warning',
                buttons: {
                    cancel: {
                        text: 'Atcelt',
                        value: null,
                        visible: true,
                        className: 'swal-button--cancel',
                        closeModal: true,
                    },
                    confirm: {
                        text: 'Jā',
                        value: true,
                        visible: true,
                        className: 'swal-button--confirm',
                        closeModal: true,
                    },
                },
                dangerMode: true,
            })
        },

        successAlert(message) {
            return swal({
                text: message,
                icon: 'success',
            })
        },

        warningAlert(message) {
            return swal({
                text: message,
                icon: 'warning',
            })
        },

        errorAlert(message) {
            return swal({
                text: message,
                icon: 'error',
            })
        },
    },
}
