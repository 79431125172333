import { helpers } from '@vuelidate/validators'

export default helpers.withMessage(
    () => `Lietotājvārdam jāsākas ar burtiem, var saturēt ciparus kā arī "-", "_" simbolus`,   
    (value) => {
        if (!value) {
            return true
        }

        return value.match(/^[a-z][a-z0-9\-_]*$/i)
    },
)
