import ButtonControl from './button-control'
import CardControl from './card-control.vue'
import ContentHeaderControl from './content-header-control.vue'
import ContentWrapperControl from './content-wrapper-control.vue'
import ControlSidebarControl from './control-sidebar-control.vue'
import DataTableControl from './data-table-control.vue'
import FooterControl from './footer-control.vue'
import IconControl from './icon-control.vue'
import JobRunStreamControl from './job-run-stream-control.vue'
import LoadingControl from './loading-control.vue'
import NavbarControl from './navbar-control.vue'
import PreloaderControl from './preloader-control.vue'
import SidebarControl from './sidebar-control.vue'

export default {
    ButtonControl,
    CardControl,
    ContentHeaderControl,
    ContentWrapperControl,
    ControlSidebarControl,
    DataTableControl,
    FooterControl,
    IconControl,
    JobRunStreamControl,
    LoadingControl,
    NavbarControl,
    PreloaderControl,
    SidebarControl,
}
