import useVuelidate from '@vuelidate/core'

export default {
    data() {
        return {
            v$: null,
        }
    },

    methods: {
        async checkIsInvalid() {
            const valid = await this.v$.$validate()
            return !valid
        },

        resetValidation() {
            this.v$.$reset()
        },
    },

    created() {
        this.v$ = useVuelidate()
    },
}
