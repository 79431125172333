import ButtonField from './button-field.vue'
import CheckboxField from './checkbox-field.vue'
import ErrorMessage from './error-message.vue'
import InputField from './input-field.vue'
import SelectField from './select-field.vue'
import UploadField from './upload-field.vue'

export default {
    ButtonField,
    CheckboxField,
    ErrorMessage,
    InputField,
    SelectField,
    UploadField,
}
