import { app } from '@/configs'
import axios from '@/lib/axios'

const baseUrl = app.getApiUrl('upload')

export default {
    ping() {
        return axios.get(`${baseUrl}/ping`)
    },
}
