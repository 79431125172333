import LoginPage from '@/pages/login-page.vue'
import authUtils from '@/utils/auth.utils'

export default (to) => {
    if (to.meta.public) {
        return
    }

    if (!authUtils.checkAuth()) {
        return { name: LoginPage.name }
    }
}
