import DashboardPage from '@/pages/dashboard-page.vue'
import LoginPage from '@/pages/login-page.vue'
import authUtils from '@/utils/auth.utils'

const REDIRECT_FROM = [
    LoginPage.name,
]

export default (to) => {
    if (authUtils.checkAuth() && REDIRECT_FROM.includes(to.name)) {
        return { name: DashboardPage.name }
    }
}
