import { app } from '@/configs'
import axios from '@/lib/axios'

const baseUrl = app.getApiUrl('job-run')

export default {
    list() {
        return axios.get(`${baseUrl}`)
    },

    get(id) {
        return axios.get(`${baseUrl}/${id}`)
    },

    getLog(id) {
        return axios.get(`${baseUrl}/${id}/log`)
    },

    create(job, options) {
        return axios.post(`${baseUrl}`, {
            job,
            options,
        })
    },

    start(id) {
        return axios.post(`${baseUrl}/${id}/start`)
    },

    delete(id) {
        return axios.delete(`${baseUrl}/${id}`)
    },
}
