import { app } from '@/configs'
import axios from '@/lib/axios'

const baseUrl = app.getApiUrl('role')

export default {
    list() {
        return axios.get(baseUrl)
    },
}
