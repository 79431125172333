import axiosBase from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import LogoutPage from '@/pages/logout-page.vue'
import store from '../store'
import router from '../router'

const axios = axiosBase.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: process.env.VUE_APP_API_TIMEOUT,
})

axios.interceptors.request.use(request => {
    const accessToken = store.getters['auth/accessToken']
    if (accessToken && request.url != 'auth/refresh') {
        request.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return request
})

createAuthRefreshInterceptor(axios, async () => {
    try {
        const refreshToken = store.getters['auth/refreshToken']

        // TODO: handle no refresh token

        const r = await axios.post('auth/refresh', null, {
            skipAuthRefresh: true,
            headers: {
                Authorization: `Bearer ${refreshToken}`,
            },
        })
        store.dispatch('auth/login', r.data)
    }
    catch (e) {
        router.push({ name: LogoutPage.name })
        throw e
    }
})

export default axios
