import { io } from 'socket.io-client'
import { app } from '@/configs'

const wsUrl = app.getWsUrl()
const wsPath = new URL(wsUrl).pathname

const socket = io(wsUrl, {
    path: `${wsPath}/socket.io`,
    auth: {
        token: null,
    },
    autoConnect: false,
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 60000,
})

const eventListeners = {}

socket.onAny((event, data) => {
    (eventListeners[event] || []).forEach(listener => {
        listener(data)
    })
})

export default {
    connect() {
        socket.connect()
    },

    disconnect() {
        socket.disconnect()
    },

    getIsConnected() {
        return socket.connected
    },

    setAccessToken(token) {
        socket.auth.token = token
    },

    emit(event, ...args) {
        socket.emit(event, ...args)
    },

    emitWithAck(event, ...args) {
        return socket.emitWithAck(event, ...args)
    },

    addEventListener(event, listener) {
        if (!eventListeners[event]) {
            eventListeners[event] = []
        }

        if (!eventListeners[event].includes(listener)) {
            eventListeners[event].push(listener)
        }
    },

    removeEventListener(event, listener) {
        if (!eventListeners[event]) {
            eventListeners[event] = []
        }

        const idx = eventListeners[event].indexOf(listener)

        if (idx >= 0) {
            eventListeners[event].splice(idx, 1)
        }
    },
}
