import axios from '../lib/axios'
import store from '../store'

export default {
    async login(username, password, remember = false) {
        const loginData = (await axios.post('auth/login', {
            username,
            password,
            remember,
        }, {
            skipAuthRefresh: true,
        })).data

        store.dispatch('auth/login', {
            accessToken: loginData.accessToken,
            refreshToken: loginData.refreshToken,
        })

        const profile = (await axios.get('profile', { skipAuthRefresh: true })).data
        store.dispatch('auth/setCurrentUser', profile)
    },

    logout() {
        const token = store.getters['auth/refreshToken']
        return axios.post('auth/logout', {}, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    },
}
