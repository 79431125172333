import HomePage from '../pages/home-page.vue'
import LoginPage from '../pages/login-page.vue'
import LogoutPage from '../pages/logout-page.vue'
import ProfilePage from '@/pages/profile-page.vue'
import DashboardPage from '../pages/dashboard-page.vue'
import JobRunsPage from '../pages/job-runs-page.vue'
import CreateJobRunPage from '../pages/create-job-run-page.vue'
import JobRunLogPage from '../pages/job-run-log-page.vue'
import JobRunStreamPage from '../pages/job-run-stream-page.vue'
import JobRunPage from '../pages/job-run-page.vue'
import JobsPage from '../pages/jobs-page.vue'
import RobotsPage from '@/pages/robots-page.vue'
import RobotPage from '@/pages/robot-page.vue'
import UsersPage from '@/pages/users-page.vue'
import CreateUserPage from '@/pages/create-user-page.vue'
import UserPage from '@/pages/user-page.vue'

export default [
    {
        path: '/',
        name: HomePage.name,
        component: HomePage,
        meta: {
            layout: 'raw',
            public: true,
        },
    },
    
    {
        path: '/login',
        name: LoginPage.name,
        component: LoginPage,
        meta: {
            layout: 'auth',
            public: true,
        },
    },
    {
        path: '/logout',
        name: LogoutPage.name,
        component: LogoutPage,
        meta: {
            layout: 'auth',
            public: true,
        },
    },

    {
        path: '/profile',
        name: ProfilePage.name,
        component: ProfilePage,
        meta: {
            title: 'Mans profils',
        },
    },

    {
        path: '/dashboard',
        name: DashboardPage.name,
        component: DashboardPage,
        meta: {
            title: 'Vadības panelis',
        },
    },
    {
        path: '/job-run',
        name: JobRunsPage.name,
        component: JobRunsPage,
        meta: {
            title: 'Uzdevumi',
        },
    },
    {
        path: '/job-run/create/:tag',
        name: CreateJobRunPage.name,
        component: CreateJobRunPage,
        props: true,
        meta: {
            title: 'Jauns uzdevums',
        },
    },
    {
        path: '/job-run/:jobRunId',
        name: JobRunPage.name,
        component: JobRunPage,
        props: true,
        meta: {
            title: 'Uzdevums',
        },
    },
    {
        path: '/job-run/:jobRunId/log',
        name: JobRunLogPage.name,
        component: JobRunLogPage,
        props: true,
        meta: {
            title: 'Uzdevuma izpildes žurnāls',
            layoutContentWrapper: false,
        },
    },
    {
        path: '/job-run/:jobRunId/stream',
        name: JobRunStreamPage.name,
        component: JobRunStreamPage,
        props: true,
        meta: {
            title: 'Uzdevuma darbvirsmas straume',
            layoutContentWrapper: false,
        },
    },

    {
        path: '/job',
        name: JobsPage.name,
        component: JobsPage,
        meta: {
            title: 'Uzdevumu veidi',
        },
    },

    {
        path: '/robot',
        name: RobotsPage.name,
        component: RobotsPage,
        meta: {
            title: 'Roboti',
        },
    },
    {
        path: '/robot/:robotId',
        name: RobotPage.name,
        component: RobotPage,
        props: true,
        meta: {
            title: 'Robots',
        },
    },

    {
        path: '/user',
        name: UsersPage.name,
        component: UsersPage,
        meta: {
            title: 'Lietotāji',
            layoutContentWrapper: false,
        },
    },
    {
        path: '/create-user',
        name: CreateUserPage.name,
        component: CreateUserPage,
        meta: {
            title: 'Izveidot lietotāju',
        },
    },
    {
        path: '/user/:userId',
        name: UserPage.name,
        component: UserPage,
        props: true,
        meta: {
            title: 'Lietotājs',
        },
    },
]
