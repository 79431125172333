import { app } from '@/configs'
import axios from '@/lib/axios'

const baseUrl = app.getApiUrl('user')

export default {
    list() {
        return axios.get(baseUrl)
    },

    get(id) {
        return axios.get(`${baseUrl}/${id}`)
    },

    create(data) {
        return axios.post(baseUrl, data)
    },

    update(id, data) {
        return axios.put(`${baseUrl}/${id}`, data)
    },

    activate(id) {
        return axios.post(`${baseUrl}/${id}/activate`)
    },

    deactivate(id) {
        return axios.post(`${baseUrl}/${id}/deactivate`)
    },

    unique(fields, exceptId) {
        return axios.get(`${baseUrl}/unique`, {
            params: {
                ...fields,
                exceptId,
            },
        })
    },

    delete(id) {
        return axios.delete(`${baseUrl}/${id}`)
    },
}
