import Moment from 'moment'

export default {
    formatDate(d) {
        return d ? this.isoToMoment(d).format('D MMM, YYYY') : ''
    },

    formatDateTime(d) {
        return d ? this.isoToMoment(d).format('D MMM, YYYY HH:mm') : ''
    },

    isoToMoment(d) {
        return typeof(d) == 'string' ? Moment(d) : d
    },
}
