import { app } from '@/configs'
import axios from '@/lib/axios'

const baseUrl = app.getApiUrl('profile')

export default {
    update(data) {
        return axios.put(`${baseUrl}`, data)
    },

    changePassword(password, newPassword) {
        return axios.post(`${baseUrl}/password-change`, {
            password,
            newPassword,
        })
    },
}
