import { createApp } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import App from './app.vue'
import router from './router'
import controls from './controls'
import forms from './forms'
import layouts from './layouts'
import views from './views'
import store from './store'

import '../node_modules/admin-lte/dist/js/adminlte.min.js'
import '../node_modules/admin-lte/plugins/select2/js/select2.full.min.js'
import '../node_modules/admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js'
import '../node_modules/admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js'
import '../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.js'
import '../node_modules/admin-lte/plugins/jquery-knob/jquery.knob.min.js'
import '../node_modules/admin-lte/plugins/sparklines/sparkline.js'
import '../node_modules/admin-lte/plugins/jquery/jquery.min.js'
import '../node_modules/admin-lte/plugins/jquery-ui/jquery-ui.min.js'
import '../node_modules/admin-lte/plugins/inputmask/jquery.inputmask.min.js'
import '../node_modules/admin-lte/plugins/bootstrap-switch/js/bootstrap-switch.min.js'
import '../node_modules/admin-lte/plugins/bs-stepper/js/bs-stepper.min.js'
import '../node_modules/admin-lte/plugins/dropzone/min/dropzone.min.js'

import '../node_modules/admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css'
import '../node_modules/admin-lte/plugins/summernote/summernote-bs4.min.css'
import '../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.css'
import '../node_modules/admin-lte/plugins/jqvmap/jqvmap.min.css'
import '../node_modules/admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css'
import '../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css'
import '../node_modules/admin-lte/plugins/bootstrap4-duallistbox/bootstrap-duallistbox.min.css'
import '../node_modules/admin-lte/plugins/bs-stepper/css/bs-stepper.min.css'
import '../node_modules/admin-lte/plugins/dropzone/min/dropzone.min.css'
import '../node_modules/admin-lte/dist/css/adminlte.min.css'

window.$ = window.jQuery = require('jquery')

library.add(fas, fab, far)
dom.watch()

const app = createApp(App)

app.use(router)
app.use(store)

const allComponents = {
    ...controls,
    ...forms,
    ...layouts,
    ...views,
}
Object.values(allComponents).forEach(component => {
    app.component(component.name, component)
})
app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')
