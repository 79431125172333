import { createRouter, createWebHistory } from 'vue-router'
import { globalGuards } from '../guards'
import routes from '../routes'

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

globalGuards.forEach(g => router.beforeEach(g))

export default router
