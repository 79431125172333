import { app } from '@/configs'
import axios from '@/lib/axios'

const baseUrl = app.getApiUrl('job')

export default {
    list() {
        return axios.get(`${baseUrl}`)
    },

    getJobForm(tag) {
        return axios.get(`${baseUrl}/${tag}/form`)
    },
}
