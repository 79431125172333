export default {
    getApiUrl(relativeUrl = '') {
        let url = process.env.VUE_APP_API_URL.trimEnd('/')

        if (relativeUrl) {
            url += `/${relativeUrl.trim('/')}`
        }

        return url
    },

    getWsUrl() {
        return process.env.VUE_APP_WS_URL
    },
}
