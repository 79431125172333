import { mapActions, mapGetters } from 'vuex'
import authStore from '@/store/auth.store'

export default {
    computed: mapGetters('auth', Object.keys(authStore.getters)),

    methods: {
        ...mapActions('auth', Object.keys(authStore.actions)),

        hasCapabilities(...capabilities) {
            return capabilities.every(c => this.currentUser?.capabilities.includes(c))
        },
    },
}
