import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import authStore from './auth.store'

export default createStore({
    modules: {
        auth: authStore,
    },

    plugins: [
        createPersistedState({
            paths: ['auth'],
        }),
    ],
})
