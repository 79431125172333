<script>
import authStoreMixin from '@/mixins/store/auth-store-mixin'
import DashboardPage from './dashboard-page.vue';
import LoginPage from './login-page.vue';

export default {
    name: 'home-page',

    mixins: [
        authStoreMixin,
    ],

    created() {
        if (this.currentUser) {
            this.$router.push({ name: DashboardPage.name })
        } else {
            this.$router.push({ name: LoginPage.name })
        }
    },
}
</script>
